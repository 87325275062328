import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { breakpoints, color, wrapper, sizing } from '../../utils/style';
import FooterMenuLeft from '../Menus/FooterMenuLeft';
import FooterMenuRight from '../Menus/FooterMenuRight';
import ButtonLink from '../shared/ButtonLink';

import Logo from './LogoSvg';
import Freshchat from './Freshchat';
import StoreContext from '../shared/Context/StoreContext';
import ResetPasswordFormPopup from '../Auth/Form/ResetPasswordFormPopup';
import LoginFormPopup from '../Auth/Form/LoginFormPopup';
import ResetLinkFormPopup from '../Auth/Form/ResetLinkFormPopup';
import RegistrationFormPopup from '../Auth/Form/RegistrationFormPopup';

const FooterBlock = styled('footer')`
  margin-top: 20px;
`;

const Wrapper = styled('div')`
  ${wrapper}
  padding: 40px 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  
  &.footer-bottom {
    border-top: 1px solid ${color.grey};
    font-size: ${sizing.text};
    color: ${color.textGrey};
    a {
      color: ${color.textGrey};
    }
    .footer-col {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
  
  ul {
    list-style: none;
    a {
      color: ${color.black};
      &:hover, &:active {
        color: ${color.red};
      }
    }
  }
  
  li {
    margin-bottom: 10px;
  }
  
  .footer-col {
    flex-basis: 25%;
    &-mobile {
      display: none;
      .social-buttons {
        margin-bottom: 40px;
      }
    }
  }
  .footer-col-wide {
    flex-basis: 48%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  
  .col {
    flex-basis: 48%;
  }
  
  .contacts-subheader {
    color: ${color.red};
    font-size: 20px;
    margin-bottom:20px;
  }
  
  .green-button {
    width: 164px;
    padding: 0 40px 0 35px; 
  }
  
  @media (max-width: ${breakpoints.md}) {
    padding: 30px 20px;
    flex-direction: column;
    position: relative;
    
    .contacts-subheader {
      display: none;
    }
    
    .green-button {
      position: absolute;
      top: 30px;
      right: 20px;
      padding: 0 15px 0 25px;
      width: auto;
      span {
        display: none;
      }
    }
    
    &.footer-bottom {
      .footer-col {
        display: none;
      }
    }
    
    .footer-menus {
      border-top: 1px solid ${color.grey};
      padding-top: 30px;
      margin-top: 30px;
    }
    
  }
`;





export default () => {
  const value = useContext(StoreContext);
  const username = value.username;
  const d = new Date();
  const year = d.getFullYear();
  return (<FooterBlock>
    <Wrapper>
      <div className={"footer-col"}>
        <Logo to="/" text={process.env.OWNER}/>
      </div>
      <div className="footer-menus footer-col-wide">
        <div className={"col col-left"}>
          <FooterMenuLeft/>
        </div>

        <div className={"col col-right"}>
          <FooterMenuRight/>
        </div>
      </div>
      <div className="footer-col">
        <p className={"contacts-subheader"}>ติดต่อ</p>
        <ButtonLink to={process.env.LINE_ID_EXT} text={"Line"} iconName={"line"} iconColor={color.white}
                    external={true} iconLeft={true} iconSize={36} className={"button green-button"} ariaText={"Line"}/>
      </div>
    </Wrapper>
    <Wrapper className={"footer-bottom"}>
      <div className={"footer-col-wide"}>
        <span>Copyright © {year}  {`${process.env.COPY}`}</span>
      </div>
      <div className={"footer-col"}>
        <Link to={process.env.TERMS_OF_USE_URL}>เงื่อนไขการใช้บริการ</Link>
        <Link to={"/privacy-policy/"}>นโยบายความเป็นส่วนตัว</Link>
      </div>
    </Wrapper>
    <Freshchat/>
    {username ? <ResetPasswordFormPopup popupId={'reset-password-popup'}/> :
      <>
        <LoginFormPopup popupId="login-popup-window"/>
        <ResetLinkFormPopup popupId="reset-link-popup"/>
        <RegistrationFormPopup popupId="registration-popup"/>
      </>

    }
  </FooterBlock>)
};