import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints, font } from '../../utils/style';
import { isMobile } from 'react-device-detect';
import ArrowTop from '../../icons/svg/ArrowTop';

const MAX_MENU_ITEMS = 9;

const Ul = styled('ul')`
  list-style: none;
  margin: 0 20px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  .top-menu-li {
    display: inline-flex;
    margin: 0;
    padding: 0;
    &:last-of-type {
      a {
        padding-right: 0;
      }
    }
    &.open-item {
      .top-menu-a {
        color: ${color.red};
      }
      .submenu {
        display: block;
      }
    }
  }
  .top-menu-a {
    display: block;
    color: ${color.black};
    padding: 9px 13px;
    text-transform: uppercase;
    line-height: 1.2;

    &:hover,
    &:focus {
      color: ${color.red};
      text-decoration: none;
    }
    &:active,
    &.active {
      color: #000;
      font-weight: ${font.boldWeight};
      text-decoration: none;
    }
  }
  
  .mobile-arrow {
    display: none;
    &.left {
      margin-right: 15px;
    }
    &.right {
      margin-left: 15px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
    margin: 0 20px;
    &.open {
      display: block;
    }
    .top-menu-li { 
      display: block;
      text-align: center;
      &.open-item {
        position: relative;
        background: ${color.white};
        border-radius: 24px;
        box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);
        .top-menu-a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .mobile-arrow {
          display: inline-flex;
        }
      }
      &:last-of-type {
        a {
          padding-right: 45px;
        }
      }
    }
    .top-menu-a {
      display: block;
      padding: 15px;
      text-align: center;
      font-size: 24px;
      line-height: 40px;
      font-weight: 700;
    } 
  }

`;

const SubMenuWrap = styled('div')`
  display: none;
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  z-index: 10;
  background: ${color.azul};
  background-image: linear-gradient(to left, #57ffeb, #ff989c);
  border-radius: 24px;
  padding: 1px;
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    background: ${color.white};
    background-image: linear-gradient(to left, #effffd, #fff9f8);
    border-radius: 24px;
    padding: 20px;
    box-shadow: 0 24px 48px rgba(0,0,0,0.05);
  }
  li {
    display: inline-flex;
    padding: 5px;
  }
  a {
    display: inline-flex;
    padding: 5px 10px;
    color: ${color.black};
    &:hover, &:active {
      color: ${color.red};
      text-decoration: none;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    background: none;
    position: static;
    border-radius: 0;
    border: none;
    ul {
      padding-top: 0;
      display: block;
      background-image: none;
      border-radius: 0 0 24px 24px;
      background: none;
    }
    li, a {
      display: block;
    }
  }
`;

const closeMenuItems = () => {
  let elems = document.querySelectorAll('li.open-item');
  [].forEach.call(elems, function (el) {
    el.classList.remove('open-item');
  });
};

const handleMenuClick = (e, hasChildren) => {
  let menuItem = getClosest(e.target, 'li');
  let hasSubMenu = menuItem.getElementsByClassName('submenu');
  if (isMobile && hasChildren && (!menuItem.classList.contains('open-item') || menuItem.classList.contains('no-link'))) {
    e.preventDefault();
    closeMenuItems();
    if (hasSubMenu.length) {
      menuItem.classList.add('open-item');
    }
  }
};

const handleMouseEnter = e => {
  let menuItem = getClosest(e.target, 'li');
  let hasSubMenu = menuItem.getElementsByClassName('submenu');
  if (!isMobile) {
    closeMenuItems();
    if (!menuItem.classList.contains('open-item') &&
      !menuItem.classList.contains('home') && hasSubMenu.length) {
      menuItem.classList.add('open-item');
    }
  }
};

const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) { }
        return i > -1;
      };
  }

  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};


export default ({ activeTopMenuLink }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main-menu" } }) {
          nodes {
            id
            name
            slug
            items {
              title
              classes
              attr
              object_slug
              path
              target
              url
              wordpress_children {
                attr
                classes
                description
                path
                title
                xfn
                object_slug
                wordpress_id
                target
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={'top-menu-wrap'}>
        {data.allWordpressWpApiMenusMenusItems.nodes?.[0]?.items.length < MAX_MENU_ITEMS && (
          <Ul className={'menu-top'} id="nav">
            {data.allWordpressWpApiMenusMenusItems.nodes?.[0]?.items.map(item => {
              const pathTo = '/' + item.path.replace('category/', '');
              const activeClass = `/${activeTopMenuLink}/` === pathTo ? 'active ' : '';

              return (
                <li key={item.title + item.object_slug} className={'top-menu-li'}>
                  {item.target ? (
                    <a className={item.classes ? 'top-menu-a ' + item.classes : 'top-menu-a'} href={item.url}
                       rel="noopener noreferrer" onClick={e => handleMenuClick(e, item.wordpress_children)}
                       onMouseEnter={e => handleMouseEnter(e)}>
                      {item.wordpress_children && <ArrowTop className="mobile-arrow left"/>}
                      <span className="item-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                      {item.wordpress_children && <ArrowTop className="mobile-arrow right"/>}
                    </a>
                  ) : (
                    <Link className={activeClass + (item.classes ? 'top-menu-a ' + item.classes : 'top-menu-a')}
                          activeClassName="active" to={pathTo} onClick={e => handleMenuClick(e, item.wordpress_children)}
                          onMouseEnter={e => handleMouseEnter(e)}>
                      {item.wordpress_children && <ArrowTop className="mobile-arrow left"/>}
                      <span className="item-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                      {item.wordpress_children && <ArrowTop className="mobile-arrow right"/>}
                    </Link>
                  )}

                  {item.wordpress_children && (
                    <SubMenuWrap className="submenu" onMouseLeave={closeMenuItems}>
                      <ul>
                        {item.wordpress_children.map(subitem => (
                          <li
                            key={subitem.wordpress_id + Math.random()}
                            className={'submenu-item ' + subitem.classes}>
                            {subitem.target ? (
                              <a href={subitem.url} target={subitem.target} >
                                <span className="subitem-title" dangerouslySetInnerHTML={{ __html: subitem.title }} />
                              </a>
                            ) : (
                              <Link activeClassName="active-sub" to={'/' + subitem.path.replace('category/', '')}>
                                <span className="subitem-title" dangerouslySetInnerHTML={{ __html: subitem.title }} />
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </SubMenuWrap>
                  )}

                </li>
              );
            })}
          </Ul>
        )}
      </div>
    )}
  />
);
