import React from "react";

const Logo = ({
  width = "94",
  className = "",
  height = "30",
  viewBox = "0 0 94 30",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient id="h0c1mh0q1b" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#00DAC7"/>
        <stop offset="100%" stopColor="#00A390"/>
      </linearGradient>
      <linearGradient id="0h76yn51ic" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFAC6C"/>
        <stop offset="100%" stopColor="#F63940"/>
      </linearGradient>
      <filter id="do9scpx0va">
        <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.937255 0 0 0 0 0.388235 0 0 0 0 0.243137 0 0 0 1.000000 0"/>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path fill="url(#h0c1mh0q1b)" fillRule="nonzero" d="M79.645 0c7.88 0 14.28 6.72 14.28 14.6 0 7.92-6.4 14.52-14.28 14.52-7.88 0-14.28-6.6-14.28-14.52 0-7.88 6.4-14.6 14.28-14.6zM63.6.48v5.2h-5.96v22.88h-5.32V5.68h-5.96V.48H63.6zm16.045 9.08c-.294 0-.56.198-.73.543l-1.39 2.815-3.105.451c-.381.055-.652.248-.743.527-.09.28.015.594.291.863l2.247 2.19-.53 3.094c-.068.396.048.633.156.762.128.152.315.235.525.235.159 0 .327-.046.501-.138l2.778-1.46 2.778 1.46c.174.092.343.138.501.138.21 0 .397-.083.525-.235.109-.13.225-.366.157-.762l-.53-3.093 2.247-2.19c.275-.27.381-.584.29-.864-.09-.28-.361-.472-.742-.527l-3.106-.451-1.389-2.815c-.17-.345-.437-.543-.73-.543z" transform="translate(-135 -41) translate(135 32) translate(0 9)"/>
            <path fill="url(#0h76yn51ic)" fillRule="nonzero" d="M30.36 0c7.88 0 14.28 6.72 14.28 14.6 0 7.92-6.4 14.52-14.28 14.52-7.88 0-14.28-6.6-14.28-14.52 0-7.88 6.4-14.6 14.28-14.6zM5.32.56v22.92h8.8v5.08H0v-28h5.32zm25.04 6c-.294 0-.56.198-.73.543l-1.39 2.815-3.106.451c-.38.055-.651.248-.742.527-.091.28.015.594.29.863l2.248 2.19-.53 3.094c-.068.396.047.633.156.762.128.152.314.235.525.235.159 0 .327-.046.501-.138l2.778-1.46 2.778 1.46c.174.092.342.138.501.138.21 0 .397-.083.525-.235.109-.13.225-.366.157-.762l-.531-3.093 2.247-2.19c.276-.27.382-.584.291-.864-.09-.28-.361-.472-.742-.527l-3.106-.451-1.39-2.815c-.17-.345-.436-.543-.73-.543z" transform="translate(-135 -41) translate(135 32) translate(0 9)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
