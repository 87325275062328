import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { color, formStyles, breakpoints, font } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { registerUrl } from '../Config.js';
//import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext';
import { closePopup, invokePopup } from '../../shared/PopupWindow';

import IcomoonIcon from '../../shared/IcomoonIcon';


const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  padding: 20px 0;
  text-align: left;
  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
      &:first-of-type {
        margin-right: 4%;
      }
    }
  }
  
  .checkboxGroupWrap {
    display: none;
  }
  
  .button {
    min-width: 200px;
    margin: 0;
    width: 100%;
    svg {
      margin-left: 1px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  
  .top-notice {
    margin: -10px 0 20px;
  }
  
  .bottom-part {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
  }
  
  .login-link {
    margin-left: 10px;
    color: ${color.red};
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    font-family: ${font.default};
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .two-fields {
      .fieldWrap {
        width: 100%;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

const MsgWrap = styled('div')`
  span {
    display: none;
    background-color: ${color.green};
    color: ${color.white};
    padding: 15px 20px;
    text-align: center;
    border-radius: 10px;
    &.visible {
      display: block;
      margin-bottom: 20px;
    }
  }
`;



const showErrorMsg = msg => {
  const errorMsg = document.getElementById('registrationErrorMsgPage');
  errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

/*const showSuccessMsg = () => {
  const errorMsg = document.getElementById('registrationErrorMsgPage');
  errorMsg.classList.remove('visible');
  const successMsg = document.getElementById('registrationSuccessMsgPage');
  successMsg.classList.add('visible');
};

  const hideMsg = () => {
  const successMsg = document.getElementById('registrationSuccessMsgPage');
  successMsg.classList.remove('visible');
  const errorMsg = document.getElementById('registrationErrorMsgPage');
  errorMsg.classList.remove('visible');
};*/

const onSubmit = (values, { resetForm, setSubmitting }) => {
  let body = JSON.stringify(values);
  fetch(registerUrl, {
    method: 'post',
    body: body,
  })
    .then(function(response) {
      return response.text();
    })
    .then(function(body) {
      let data = JSON.parse(body);
      if (data.result * 1 === 0 && data.message) {
        showErrorMsg(data.message);
        setSubmitting(false);
      } else {
        resetForm({});
        navigate('/register-success/');
        /*if (values.register_type && values.register_type.real_money) {
          invokePopupInitial('register-line-popup');
        } else {
          showSuccessMsg(data.message);
        }*/
      }
    })
    .catch(function(error) {
      console.log('Send Request failed', error);
    });
};

const initialValues = {
  username: '',
  email: '',
  phone: '',
  line: '',
  password: '',
  register_type: { real_money: false, free_speen: true },
};

// Checkbox input
const Checkbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  ...props
}) => {
  return (
    <div className="minimal-custom-checkbox">
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const FormBody = ( { isRegPage } ) => {
  const loginFormOpen = (e, loginPopupId) => {
    e.preventDefault();
    closePopup(e, "registration-popup");
    invokePopup(e, loginPopupId);
  };

  const viewPassClick = (e) => {
    e.preventDefault();
    const passField = document.getElementById('register-password-static');
    if (passField.type === "password") {
      passField.type = "text";
    } else {
      passField.type = "password";
    }
  };
  return (
    <Wrapper>
      {!isRegPage &&
        <p className="a-center top-notice">ลงทะเบียนเพื่อเล่นฟรีสปิน</p>
      }

      <MsgWrap id="registrationMsgPage">
        <span className="errorMsg" id="registrationErrorMsgPage">
          การส่งแบบฟอร์มล้มเหลว กรุณาลองอีกครั้ง
        </span>
        <span className="successMsg" id="registrationSuccessMsgPage">
          ส่งแบบฟอร์มเรียบร้อยแล้ว! ตรวจสอบอีเมลของคุณและเปิดใช้งานบัญชี
        </span>
      </MsgWrap>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('จำเป็นต้องใช้'),
          email: Yup.string()
            .email()
            .required('จำเป็นต้องใช้'),
          password: Yup.string().required('จำเป็นต้องใช้'),
          phone: Yup.string().required('จำเป็นต้องใช้'),
          line: Yup.string().required('จำเป็นต้องใช้'),
        })}>
        {({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="regStaticForm">
            <div className={"fieldWrap" + ((errors.username && touched.username) ? " has-error" : "")}>
              <Field
                type="text"
                name="username"
                placeholder="ยูสเซอร์เนม"
                aria-label="ยูสเซอร์เนม"
                id="register-username-static"
                value={values.username || ''}
              />
              {errors.username && touched.username && (
                <div className="error">! {errors.username}</div>
              )}
            </div>
            <div className={"fieldWrap" + ((errors.email && touched.email) ? " has-error" : "")}>
              <Field
                type="email"
                name="email"
                placeholder="อีเมล"
                aria-label="อีเมล"
                value={values.email || ''}
              />
              {errors.email && touched.email && (
                <div className="error">! {errors.email}</div>
              )}
            </div>
            <div className={"fieldWrap" + ((errors.password && touched.password) ? " has-error" : "")}>
              <button className="view-button icon-button" aria-label={"View"} onClick={(e) => viewPassClick(e)}>
                <IcomoonIcon icon="open" color={color.red} size={20} />
              </button>
              <Field
                type="password"
                name="password"
                placeholder="รหัสผ่าน"
                aria-label="รหัสผ่าน"
                id="register-password-static"
                value={values.password || ''}
              />
              {errors.password && touched.password && (
                <div className="error">! {errors.password}</div>
              )}
            </div>
            <div className="two-fields">
              <div className={"fieldWrap" + ((errors.phone && touched.phone) ? " has-error" : "")}>
                <Field
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  aria-label="Phone"
                  value={values.phone || ''}
                />
                {errors.phone && errors.phone && touched.phone && (
                  <div className="error">! {errors.phone}</div>
                )}
              </div>
              <div className={"fieldWrap" + ((errors.line && touched.line) ? " has-error" : "")}>
                <Field
                  type="text"
                  name="line"
                  placeholder="LINE ID"
                  aria-label="LINE ID"
                  value={values.line || ''}
                />
                {errors.line && touched.line && (
                  <div className="error">! {errors.line}</div>
                )}
              </div>
            </div>
            <div className="checkboxGroupWrap two-fields">
              <p>เกมที่คุณสนใจ</p>

              <div className="fieldWrap checkboxWrap">
                <Field component={Checkbox} name="register_type[free_speen]"
                       id="register-type-free-speen-static"
                       label="สมัครเล่น สล็อตฟรีสปิน"
                       value={values.register_type && values.register_type.free_speen
                         ? values.register_type.free_speen : false}/>
              </div>

              <div className="fieldWrap checkboxWrap">
                <Field
                  component={Checkbox}
                  name="register_type[real_money]"
                  id="register-type-real-money-static"
                  label="สมัครเล่นคาสิโน"
                  value={values.register_type && values.register_type.real_money
                    ? values.register_type.real_money : false}/>
              </div>

              {Object.keys(errors).length === 1 && errors.register_type && (
                <div className="error">! {errors.register_type}</div>
              )}
            </div>

            {status && status.msg && <div>{status.msg}</div>}
            {!isSubmitting ?
              <button aria-label="สมัครสมาชิก" className="button red-button" type="submit"
                      disabled={!isValid}>
                <span>สมัครสมาชิก</span>
              </button>
              : <button aria-label="สมัครสมาชิก" className="button red-button" type="submit"
                        disabled={(!isValid || isSubmitting)}>
                <span>สมัครสมาชิก</span>
                <IcomoonIcon icon="loader" color={color.transparentWhite7} size={16}/>
              </button>
            }
            {!isRegPage &&
              <div className="bottom-part">
                <span>Already have an account?</span>
                <button aria-label="สมัครสมาชิก" className="login-link inline-button"
                    onClick={e => loginFormOpen(e, "login-popup-window")}>
                  Login here
                </button>
              </div>
            }
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const RegistrationFormStatic = () => {
  const value = useContext(StoreContext);
  const username = value.username;
  return !username ? (
    <React.Fragment>
      <FormBody/>
    </React.Fragment>
  ) : (
    'You need to be logged out to register'
  );
};

export default RegistrationFormStatic;
