import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, formStyles, sizing } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { newPasswordUrl } from '../Config.js';
import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext';
import withLocation from '../../shared/withLocation';
import { getAuthHeader } from '../Utils';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  .errorMsg, .successMsg {
    display: none;
    &.visible {
      display: block;
      & + div, & ~ p, & ~ form {
        display: none;
      }
    }
  }
  
  .submit-loading {
    svg {
      margin-left: 10px;
      animation: spin 2000ms linear infinite;
    }
  }
  
  .notice {
    margin: 0 0 20px;
    font-size: 14px;
    font-weight: normal;
  }

  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .button {
    margin: 0 !important;
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .inputName {
    margin-bottom: 5px;
  }
  .forgot-password {
    font-size: ${sizing.small};
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  .button {
    width: 100%;
  }
`;

const ResetPasswordForm  = (props) => {

  const StoreContextValue = useContext(StoreContext);

  const initialValues = {
    password: '',
    passwordConfirmed: '',
  };

  const search = props.search;
  const showErrorMsg = msg => {
    const errorMsg = document.getElementById('resetPassErrorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  const showSuccessMsg = () => {
    const successMsg = document.getElementById('resetPassSuccessMsg');
    successMsg.classList.add('visible');
    const popupHeader = document.getElementById('reset-password-popup-title');
    if (popupHeader) {
      popupHeader.style.display = 'none';
    }
  };

  const viewPassClick = (e, id) => {
    e.preventDefault();
    const passField = document.getElementById(id);
    if (passField.type === "password") {
      passField.type = "text";
    } else {
      passField.type = "password";
    }
  };

  const onSubmit = async (values, formApi) => {
    values.email = search.email ? search.email : '';
    values.phone = search.phone ? search.phone : '';
    values.code = search.code ? search.code : '';
    const body = JSON.stringify(values);
    const username =  StoreContextValue.username;

    let response = null;

    //for logged in user
    if(username) {
      response = await fetch(newPasswordUrl, {
        method: 'post',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthHeader()
        },
      });
    } else {
      //for not logged in user
      response = await fetch(newPasswordUrl, {
        method: 'post',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    if (response && response.ok) {
      let json = await response.json();
      if (json.success) {
        showSuccessMsg();
        formApi.setSubmitting(false);
      }
      if (json.error) {
        showErrorMsg('ขออภัย ยูสเซอร์เนมและ/หรือรหัสผ่านไม่ถูกต้อง');
        formApi.setSubmitting(false);
      }
    } else {
      let json = await response.json();
      showErrorMsg('ขออภัย ยูสเซอร์เนมและ/หรือรหัสผ่านไม่ถูกต้อง');
      formApi.setSubmitting(false);
      console.log(
        'Login HTTP Error: ' + response.status + '. Error: ' + json.message
      );
    }
  };

  return (
    <Wrapper>
      {StoreContextValue.username || Object.keys(search).length ?
        <div>
          <div className="successMsg" id="resetPassSuccessMsg">
            <IcomoonIcon icon="check" color={color.red} size={46}/>
            <div className="successMsgText">
              <p>อัปเดตรหัสผ่านของคุณเรียบร้อยแล้ว! โปรดใช้รหัสผ่านใหม่ในการเข้าสู่ระบบครั้งต่อไป</p>
            </div>
          </div>
          <div className="errorMsg" id="resetPassErrorMsg"/>
          <p className="notice">โปรดพิมพ์รหัสผ่านใหม่ด้านล่างนี้: {search.email}</p>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={values => {
              let errors = {};
              if (values.password !== values.passwordConfirmed) {
                errors.passwordConfirmed = 'รหัสผ่านไม่ตรงกัน'
              }
              if (values.password.length < 6) {
                errors.password = 'อย่างน้อย 6 ตัวอักษร'
              }
              return errors;
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required('จำเป็นต้องใช้'),
              passwordConfirmed: Yup.string().required('จำเป็นต้องใช้'),
            })}>
          {({ errors, status, touched, isSubmitting, isValid, values }) => (
              <Form id="resetPasswordForm">
                <div className="errorMsg" id="resetPassErrorMsg">
                  เกิดข้อผิดพลาด โปรดลองใหม่ในภายหลัง
                </div>
                <div className={"fieldWrap" + ((errors.password && touched.password) ? " has-error" : "")}>
                  <button className="view-button icon-button" aria-label={"View"}
                          onClick={(e) => viewPassClick(e, "password")}>
                    <IcomoonIcon icon="open" color={color.red} size={20} />
                  </button>

                  {errors.password && touched.password && (
                    <div className="error">{errors.password}</div>
                  )}
                  <Field
                    type="password"
                    name="password"
                    placeholder="รหัสผ่านใหม่"
                    aria-label="รหัสผ่านใหม่"
                    id="password"
                    value={values.password || ''}
                  />
                </div>
                <div className={"fieldWrap" + ((errors.passwordConfirmed && touched.passwordConfirmed) ? " has-error" : "")}>
                  <button className="view-button icon-button" aria-label={"View"}
                          onClick={(e) => viewPassClick(e, "confirm-password")}>
                    <IcomoonIcon icon="open" color={color.red} size={20} />
                  </button>

                  {errors.passwordConfirmed && touched.passwordConfirmed && (
                    <div className="error">{errors.passwordConfirmed}</div>
                  )}
                  <Field
                    type="password"
                    name="passwordConfirmed"
                    placeholder="ยืนยันรหัสผ่านใหม่"
                    aria-label="ยืนยันรหัสผ่านใหม่"
                    id="confirm-password"
                    value={values.passwordConfirmed || ''}
                  />
                </div>
                <BtnWrapper>
                  {!isSubmitting ?
                    <button aria-label="ส่ง" className="button red-button submit" type="submit"
                            disabled={!isValid}>
                      <span>ส่ง</span>
                    </button>
                    : <button aria-label="กำลังดำเนินการ" className="button red-button submit-loading" type="submit"
                              disabled={(!isValid || isSubmitting)}>
                      <span>กำลังดำเนินการ</span>
                      <IcomoonIcon icon="loader" color={color.transparentWhite7} size={16}/>
                    </button>
                  }
                  {status && status.msg && <div>{status.msg}</div>}
                </BtnWrapper>
              </Form>
            )}
          </Formik>
        </div> :
        <div className="a-center">
          กรุณาเข้าสู่ระบบหรือขอลิงค์รีเซ็ตรหัสผ่านเพื่ออัปเดตรหัสผ่าน
        </div>
      }

    </Wrapper>
  );
};

export default withLocation(ResetPasswordForm);

ResetPasswordForm.propTypes = {
  popupId: PropTypes.string,
};
