import React from 'react';
import styled from '@emotion/styled';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const El = styled('button')`
   .icon-holder {
     padding: 0 10px !important;
   }
   .icon {
     height: 14px;
     width:27px;
     position: relative;
     &::before {
       content: "";
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 4px;
       background-color: ${color.red};
       border-radius: 4px;
     }
     &::after{
       content: "";
       position: absolute;
       bottom: 0;
       left: 0;
       width: 100%;
       height: 4px;
       background-color: ${color.red};
       border-radius: 4px;
     }
   }
   svg {
     display: none !important;
   }
   &.clicked {
     .icon {
       display: none !important;
     }
     svg {
       display: inline-block !important;
     }
   }
`;

const Hamburger = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const ul = document.getElementById('nav');
    const layout = document.getElementById('all-content');
    layout.classList.toggle('header-block');
    ul.classList.toggle('open');
    const target = document.getElementById('mobile-menu-open');
    target.classList.toggle('clicked');
  };

  return (
    <El id="mobile-menu-open" className="button gradient-border-button mobile-menu-button" aria-label="Menu" onClick={(e) => handleClick(e)}>
      <span className="icon-holder">
        <span className="icon"/>
        <IcomoonIcon className="mobile-menu-close-icon" icon="close-menu" color={color.red} size={27} />
      </span>
    </El>
  );
};

export default Hamburger;