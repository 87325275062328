import React from 'react';
import PropTypes from 'prop-types';
import PopupWindow, { invokePopup } from '../../shared/PopupWindow';
import ResetPasswordForm from './ResetPasswordForm';


const resetPasswordFormPopupOpen = (event, popupId) => {
  event.preventDefault();
  invokePopup(event, popupId);
};

const ResetPasswordFormPopup = (popupId) => {
  return (
    <div>
      <PopupWindow
        title="เปลี่ยนรหัสผ่าน"
        popupId={popupId.popupId}
        extraClass="a-center reset-password-popup"
      >
        <ResetPasswordForm popupId={popupId.popupId} />
      </PopupWindow>
    </div>

  );
};
export default ResetPasswordFormPopup;
export { resetPasswordFormPopupOpen };

ResetPasswordFormPopup.propTypes = {
  popupId: PropTypes.string
};
