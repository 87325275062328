import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import LogoSvg from '../../icons/svg/Logo';

const Logo = ({ to, text }) => {
  return(
    <Link to={to} className="logo" title={text}>
      <LogoSvg/>
    </Link>
)};

Logo.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Logo;