import {activationUrl} from './Config.js'

const setAuthToken = (token) => {
  localStorage.setItem('Auth-Token', token);
};

const getAuthToken = () => {
  return localStorage.getItem('Auth-Token');
};

const getAuthHeader = () => {
  return 'Bearer ' + getAuthToken();
};

const resetAuthToken = () => {
  localStorage.removeItem('Auth-Token');
};

const setAuthUserName = (username) => {
  localStorage.setItem('Auth-Token-User-Name', username);
};

const getAuthUserName = () => {
  return localStorage.getItem('Auth-Token-User-Name');
};


const hasAuthToken = () => {
  let token = getAuthToken();
  return (token && token.length > 0)
};

const Logout = (event, setUsernameCallback = false) => {
  setAuthUserName('');
  setAuthToken('');
  if(setUsernameCallback){
    setUsernameCallback(false);
  }
};

const activationUser = async (path, query, activationCallback, errorCallback) => {

  const response = await fetch(activationUrl, {
    method: 'POST',
    body: JSON.stringify(query),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    let json = await response.json();
    if (json.error && json.message) {
      console.log(json.message);
      console.log(json.error_type);
      errorCallback(json.error);
    } else {
      console.log('Player Activated');
      setAuthToken(json.token);
      setAuthUserName(json.user_nicename);
      activationCallback(true);
    }
  } else {
    console.log("Activation Error HTTP: " + response.status);
  }
};

export {setAuthToken, getAuthToken, resetAuthToken, hasAuthToken, Logout, activationUser, setAuthUserName, getAuthUserName, getAuthHeader};