import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { breakpoints, color, font } from '../../utils/style';

const Popup = styled('div')`
  width: 600px;
  max-height: 98%;
  margin: 0 auto;
  position: fixed;
  top: 10%;
  left: 50%;
  margin-left: -300px;
  z-index: 100;
  display: none;
  border-radius: 15px;
  .popup-content-wrapper {
    background: ${color.white};
    border: 1px solid ${color.white};
    padding: 30px 76px;
    overflow-y: auto;
    border-radius: 15px;
    max-height: 80vh;
    @media (max-height: 500px) {
      max-height: 100vh;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &.wide {
    width: 1000px;
    margin-left: -500px;
  }
  &.padded {
    .popup-content-wrapper {
      padding: 50px;
    }
  }
  &.full-width-popup {
    width: 1304px;
    margin-left: -652px;
    .popup-content-inner {
      max-width: 325px;
      margin: 0 auto;
      padding: 5% 0;
    }
  }

  .popup-title {
    text-align: center;
    margin-top: 30px;
    padding: 0 20px;
    color: ${color.red};
    font-size: 24px;
    font-weight: ${font.boldWeight};
  }
  p {
    margin: 10px 0;
  }
  .popup-content {
    overflow-y: auto;
  }
  &.custom-game-popup {
    .popup-title {
      max-width: 50%;
      padding-right: 0;
    }
    .popup-content-wrapper {
      max-height: 100vh;
    }
    .popup-content {
      overflow-y: visible;
    }
  }
  &.popup-open {
    display: block;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    &.wide, &.full-width-popup {
      width: 800px;
      margin-left: -400px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    &.wide, &.full-width-popup {
      width: 500px;
      margin-left: -250px;
    }
    &.custom-game-popup {
      width: 100% !important;
      top: 0;
      margin-left: -50% !important;
      padding: 0;
      .close-button {
        top: 15px;
        right: 15px;
      }
      .popup-content-wrapper {
        padding: 0;
      }
      .popup-title {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        max-width: 80%;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    padding: 0;
    width: 350px;
    margin-left: -175px;
    top: 2%;
    .popup-content-wrapper {
      padding: 30px 15px;
      max-height: 92vh;
    }
    .popup-title {
      margin-top: 20px;
    }
    &.wide {
      width: 350px;
      margin-left: -175px;
    }
    &.full-width-popup {
      padding-top: 0;
      width: 350px;
      margin-left: -175px;
      .popup-content-wrapper {
        padding: 0 10px;
      }
      .popup-content-inner {
        padding: 15% 0;
      }
      .popup-title {
        padding: 0 30px;
      }
      .close-button {
        top: 10px;
        right: 15px;
        svg {
          width: 20px;
        }
      }
    }
    &.padded {
      padding: 50px 20px;
    }
  }

  @media (max-width: ${breakpoints.xxs}) {
    width: 300px;
    margin-left: -150px;
    &.wide {
      width: 300px;
      margin-left: -150px;
    }
    &.full-width-popup {
      width: 300px;
      margin-left: -150px;
    }
  }
`;

const CloseButton = styled('button')`
  background: none;
  border: none;
  outline: none;
  color: #fff;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
  opacity: 1;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 15px;
    right: 15px;
  }
`;

const closeHandler = popupId => {
  const layout = document.getElementById('all-content');
  const popupWindow = document.getElementById(popupId);
  if (popupWindow) {
    if (layout && popupWindow.classList.contains('popup-open')) {
      layout.classList.remove('popup-blocked');
    }
    popupWindow.classList.remove('popup-open');
    const iframe = document.getElementById('free-spin-home-iframe');
    if (iframe) iframe.src = 'about:blank';
  }
  if (popupId === 'login-popup-window') {
    sessionStorage.removeItem('Game-Redirect-Url');
  }

};

const invokePopup = (event, popupId) => {
  event.preventDefault();
  const layout = document.getElementById('all-content');
  layout.classList.add('popup-blocked');
  const popupWindow = document.getElementById(popupId);
  popupWindow.classList.add('popup-open');
};

const invokePopupInitial = popupId => {
  const layout = document.getElementById('all-content');
  layout.classList.add('popup-blocked');
  const popupWindow = document.getElementById(popupId);
  popupWindow.classList.add('popup-open');
};

const closePopup = (event, popupId) => {
  closeHandler(popupId);
};

const PopupWindow = ({ children, popupId, title, extraClass }) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    closeHandler(popupId);
  });
  const classNames = extraClass ? extraClass + ' popup-window' : 'popup-window';
  return (
    <Popup id={popupId} className={classNames} ref={ref}>
      <CloseButton
        onClick={() => closeHandler(popupId)}
        className="close-button"
      >
        <IcomoonIcon icon="close" color={color.black} size={26} />
      </CloseButton>
      <div className="popup-content-wrapper">
        <div className="popup-content-inner">
          {title &&
            <div id={popupId + '-title'} className="popup-title">{title}</div>
          }
          <div id={popupId + '-content'} className="popup-content">
            {children}
          </div>
        </div>

      </div>
    </Popup>
  );
};

export default PopupWindow;
export { invokePopup, closePopup, invokePopupInitial };

PopupWindow.propTypes = {
  popupId: PropTypes.string,
  title: PropTypes.string,
  extraClass: PropTypes.string,
};
