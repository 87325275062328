import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, formStyles, sizing } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { resetLinkUrl } from '../Config.js';
import IcomoonIcon from '../../shared/IcomoonIcon';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  text-align: left;
  padding: 0;
  margin: 0;
  .successMsg {
    display: none;
    &.visible {
      display: block;
      & + div, & ~ p, & ~ form {
        display: none;
      }
    }
  }
  p {
    font-size: 14px;
  }
  .submit-loading {
    svg {
      margin-left: 10px;
      animation: spin 2000ms linear infinite;
    }
  }
  
  .notice {
    margin: 0 0 20px;
    font-size: 14px;
  }
  
  .phoneField {
    position: relative;
    option[value=ZZ] {
      display: none;
    }
    .tooltip {
      position: absolute;
      right: 6px;
      top: 8px;
      z-index: 1;
    }
  }

  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .button {
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .inputName {
    margin-bottom: 5px;
  }
  .forgot-password {
    font-size: ${sizing.small};
  }
`;

const BtnWrapper = styled('div')`
  margin: 0 0 25px;
  .button {
    width: 100%;
    margin: 0 !important;
  }
`;


class ResetLinkForm extends Component {
  showErrorMsg = msg => {
    const errorMsg = document.getElementById('resetLinkErrorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  hideErrorMsg = () => {
    const errorMsg = document.getElementById('resetLinkErrorMsg');
    errorMsg.innerText = '';
    errorMsg.classList.remove('visible');
  };

  showSuccessMsg = () => {
    const successMsg = document.getElementById('resetLinkSuccessMsg');
    successMsg.classList.add('visible');
    const popupHeader = document.getElementById('reset-link-popup-title');
    popupHeader.style.display = 'none';
  };

  onSubmit = async (values, formApi) => {
    const body = JSON.stringify(values);
    console.log('Form body');
    console.log(values);
    const response = await fetch(resetLinkUrl, {
      method: 'post',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let json = await response.json();
    console.log(json);
    if (json.success) {
      formApi.resetForm();
      formApi.setSubmitting(false);
      this.showSuccessMsg(); //reset link sent
    }
    if (json.error && json.message) {
      this.showErrorMsg(json.message);
      formApi.resetForm();
      formApi.setSubmitting(false);
    }
  };

  render() {
    const initialValues = {
      phone_email: ''
    };
    return (
      <Wrapper>
        <div className="successMsg" id="resetLinkSuccessMsg">
          <IcomoonIcon icon="check" color={color.red} size={46}/>
          <div className="successMsgText">
            <p>ระบบส่งลิงก์สาหรับรีเซ็ตให้คุณเรียบร้อยแล้ว! </p>
            <p>โปรดรีเซ็ตรหัสผ่านของคุณภายใน24 ชั่วโมง</p>
            <p>หากคุณต้องการความช่วยเหลือเพิ่มเติมกรุณาติดต่อฝ่ายคอลเซ็นเตอร</p>
          </div>
        </div>
        <p className="a-center">โปรดรับลิงก์รีเซ็ตรหัสผ่านทางหมายเลขโทรศัพท์หรืออีเมล</p>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={Yup.object().shape({
            phone_email: Yup.string().required('จำเป็นต้องใช้')
          })}>
          {({ errors, status, touched, isSubmitting, isValid, values }) => (
            <Form id="resetLinkForm">
              <div className="errorMsg" id="resetLinkErrorMsg">
                เกิดข้อผิดพลาด โปรดลองใหม่ในภายหลัง
              </div>
              <div className={"fieldWrap" + ((errors.phone_email && touched.phone_email) ? " has-error" : "")}>
                {errors.phone_email && touched.phone_email && <div className="error error-popup">{errors.phone_email}</div>}
                <Field
                  type="text"
                  name="phone_email"
                  placeholder="อีเมล/หมายเลขโทรศัพท์ที่ลงทะเบียน#"
                  aria-label="อีเมล/หมายเลขโทรศัพท์ที่ลงทะเบียน#"
                  value={values.phone_email || ''}
                  onClick={this.hideErrorMsg}
                />
              </div>
              <BtnWrapper>
                {!isSubmitting ?
                  <button aria-label="ส่ง" className="button red-button submit" type="submit"
                          disabled={!isValid}>
                    <span>สส่งข้อมูล</span>
                  </button>
                  : <button aria-label="กำลังดำเนินการ" className="button red-button submit-loading" type="submit"
                            disabled={(!isValid || isSubmitting)}>
                    <span>กำลังดำเนินการ</span>
                    <IcomoonIcon icon="loader" color={color.transparentWhite7} size={16}/>
                  </button>
                }
                {status && status.msg && <div>{status.msg}</div>}
              </BtnWrapper>
              <p className="a-center">หากคุณต้องการความช่วยเหลือเพิ่มเติมกรุณาติดต่อฝ่ายคอลเซ็นเตอร</p>
            </Form>
          )}
        </Formik>
      </Wrapper>
    );
  }
}

export default ResetLinkForm;

ResetLinkForm.propTypes = {
  popupId: PropTypes.string,
};
