import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link , navigate } from 'gatsby';
import styled from '@emotion/styled';
import { color, formStyles, breakpoints } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { loginUrl } from '../Config.js';
import { setAuthToken, setAuthUserName } from '../Utils';
//import { closePopup } from '../../shared/PopupWindow';
import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext';
import { resetLinkFormPopupOpen } from './ResetLinkFormPopup';
import { RegistrationFormOpen } from './RegistrationFormPopup'

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  margin-top: 20px;
  text-align: left;
  .top-notice {
    margin: -10px 0 20px;
  }
  .button {
    margin: 0 0 1px 0;
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  
  .inline-button {
    border: none;
    background: none;
    color: ${color.red};
    outline: none;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
  }
  
  .forgot-password {
    padding: 10px 5px;
    display: inline-flex;
    &:hover, a:active {
      color: ${color.orange};
    }
  }
  .forgot-button-wrap {
    margin: -20px 0 10px;
    text-align: right;
  }
  @media (max-width: ${breakpoints.sm}) {
    p {
      margin: -5px 0 20px;
      font-size: 16px;
    }
    .forgot-button-wrap {
      text-align: center;
      margin: -15px 0 5px;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  
  .bottom-part {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
  }
  
  .register-link {
    margin-left: 10px;
  }

  .button {
    width: 100%;
  }
`;

let setUsernameCallback = false;
const FormBody = (props) => {
  const StoreContextValue = useContext(StoreContext);
  setUsernameCallback = StoreContextValue.setUsernameCallback;
  const initialValues = {
    username: '',
    password: '',
  };

  const showErrorMsg = msg => {
    const errorMsg = document.getElementById('loginErrorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  const hideErrorMsg = () => {
    const errorMsg = document.getElementById('loginErrorMsg');
    errorMsg.classList.remove('visible');
  };


  const viewPassClick = (e) => {
    e.preventDefault();
    const passField = document.getElementById('login-password');
    if (passField.type === "password") {
      passField.type = "text";
    } else {
      passField.type = "password";
    }
  };

  const closePopup = (e, popupId) => {
    const layout = document.getElementById('all-content');
    layout.classList.remove('popup-blocked');
    const popupWindow = document.getElementById(popupId);
    popupWindow.classList.remove('popup-open');
    sessionStorage.removeItem('Game-Redirect-Url');
  };

  const onSubmit = async (values, formApi) => {
    const body = JSON.stringify(values);
    const response = await fetch(loginUrl, {
      method: 'post',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      let json = await response.json();
      if (json.error && json.error_type === 'player_not_exists') {
        showErrorMsg(
          'ขออภัย ไม่มีบัญชีนี้ในระบบ โปรดสมัครบัญชีเพื่อเล่นฟรี'
        ); // Player not exists, register your account first!
        formApi.setSubmitting(false);
        return;
      }
      if (json.error && json.error_type === 'player_not_activated') {
        showErrorMsg(
          'โปรดตรวจสอบอีเมลของคุณและเปิดใช้งานบัญชีของคุณก่อน!'
        ); // Please check your email and activate your account first!
        formApi.setSubmitting(false);
        return;
      }

      let token = json.token;
      if (token) {
        setAuthToken(token);
        setAuthUserName(json.user_nicename);
        setUsernameCallback(json.user_nicename);
        const redirectUrl = sessionStorage.getItem('Game-Redirect-Url');
        if (redirectUrl) {
          sessionStorage.removeItem('Game-Redirect-Url');
          navigate(redirectUrl);
        }
        if (props.popupId) {
          closePopup(false, 'login-popup-window');
        }
      } else {
        showErrorMsg('ข้อผิดพลาดที่ไม่รู้จักลองอีกครั้ง'); // Unknown error, try again.
      }
    } else {
      let json = await response.json();
      showErrorMsg('ขออภัย ยูสเซอร์เนมและ/หรือรหัสผ่านไม่ถูกต้อง');

      console.log(
        'Login HTTP Error: ' + response.status + '. Error: ' + json.message
      );
    }
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('จำเป็นต้องใช้'),
          password: Yup.string().required('จำเป็นต้องใช้'),
        })}>
        {({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="loginForm">
            <p className="a-center top-notice">ในการเล่นฟรีสปินคุณต้องเป็นสมาชิก</p>
            <div className="errorMsg" id="loginErrorMsg">
              รส่งแบบฟอร์มล้มเหลว กรุณาลองอีกครั้ง
            </div>

            <div className={"fieldWrap" + ((errors.username && touched.username) ? " has-error" : "")}>
              {errors.username && touched.username && (
                <div className="error">{errors.username}</div>
              )}
              <Field
                type="text"
                name="username"
                placeholder="ยูสเซอร์เนม"
                aria-label="ยูสเซอร์เนม"
                id="login-username"
                value={values.username || ''}
                onClick={hideErrorMsg}
              />
            </div>

            <div className={"fieldWrap" + ((errors.password && touched.password) ? " has-error" : "")}>
              <button className="view-button icon-button" aria-label={"View"}
                      onClick={(e) => viewPassClick(e)}>
                <IcomoonIcon icon="open" color={color.red} size={20} />
              </button>

              {errors.password && touched.password && (
                <div className="error">{errors.password}</div>
              )}
              <Field
                type="password"
                name="password"
                placeholder="รหัสผ่าน"
                aria-label="รหัสผ่าน"
                id="login-password"
                value={values.password || ''}
              />
            </div>
            <BtnWrapper>
              <div className="forgot-button-wrap">
                <button aria-label="Forgot password?" className="forgot-password inline-button"
                        onClick={e => resetLinkFormPopupOpen(e, 'reset-link-popup', 'login-popup-window')}>
                  ลืมรหัสผ่าน?
                </button>
              </div>

              <div className="register-button-wrap">
                <button aria-label="เข้าสู่ระบบ" className="button red-button full-width" type="submit"
                        disabled={isSubmitting || !isValid}>
                  <span>เข้าสู่ระบบ</span>
                </button>
              </div>

              {status && status.msg && <div>{status.msg}</div>}
              <div className="bottom-part">
                <span>Don't have an account?</span>
                <Link aria-label="สมัครสมาชิก" className="register-link inline-button" to="/register/"
                      onClick={e => RegistrationFormOpen(e, "registration-popup", "login-popup-window")}>
                  สมัครสมาชิก
                </Link>
              </div>

            </BtnWrapper>
          </Form>
        )}
      </Formik>

    </Wrapper>
  );
};

export default FormBody;

FormBody.propTypes = {
  popupId: PropTypes.string
};
