import React from 'react';
const ArrowTop = ({ width = "24", className = "", height = "24", viewBox = "0 0 24 24", }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#EF633E" fillRule="evenodd" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-1C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm-4.83-7.026l4.547-3.169c.175-.067.458-.067.632.001l4.481 3.167c.175.068.222-.002.107-.156l-4.695-7.7c-.115-.155-.303-.156-.42-.002l-4.758 7.703c-.117.154-.07.224.105.156z"/>
  </svg>
);

export default ArrowTop;
