import React from 'react';
import PropTypes from 'prop-types';
import PopupWindow, { closePopup, invokePopup } from '../../shared/PopupWindow';
import RegistrationFormStatic from './RegistrationFormStatic';

const RegistrationFormOpen = (event, popupId, loginPopupId) => {
  event.preventDefault();
  closePopup(event, loginPopupId);
  invokePopup(event, popupId);
};

const RegistrationFormPopup = ({popupId}) => {
  return (
    <PopupWindow
      title="Register"
      popupId={popupId}
      extraClass="title-orange register-popup"
    >
      <RegistrationFormStatic />
    </PopupWindow>
  );
};
export default RegistrationFormPopup;
export { RegistrationFormOpen };

RegistrationFormPopup.propTypes = {
  popupId: PropTypes.string,
};
