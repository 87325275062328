import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';


const HeaderLineLink = () => {
  return (
    <a href={process.env.LINE_ID_EXT} className="button green-button" aria-label="Line"
       target="_blank" rel="noopener noreferrer">
      <IcomoonIcon className="contact-menu-icon" icon="line" color={color.white} size={30} />
    </a>
  );
};

export default HeaderLineLink;