import React, { useEffect } from 'react';
import MainMenu from '../Menus/MainMenuSimple';
import Logo from './LogoSvg';
import styled from '@emotion/styled';
import { wrapper, breakpoints, color } from '../../utils/style';
import Hamburger from '../Menus/Icons/Hamburger';
import HeaderLineLink from '../Menus/Icons/HeaderLineLink';

const AppHeader = styled('header')`
  margin: 25px 0;  
  .desktop-wrapper {
    ${wrapper}
    display:flex;
    flex-flow: row wrap;
    justify-content:space-between;
    align-items: center;
  }
  .logo {
    display: inline-flex;
  }
  .green-button {
    width: 90px;
  }
  
  .mobile-menu-button {
    display: none;
  }
  
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    margin: 0;
    padding: 17px 0 20px;
    background: ${color.white};
    background-image: linear-gradient(to right,#fff9f8,#effffd),linear-gradient(to left,#57ffeb,#ff989c 2%);
    box-shadow: 0 24px 48px 0 rgb(0, 0, 0, 0.05);
  }
  
  @media (max-width: ${breakpoints.md}) {
    position: relative;
    .mobile-menu-button {
      display: inline-flex;
    }
    .green-button {
      margin-left: auto;
      margin-right: 20px;
    }
  
    .top-menu-wrap {
      display: block;
      position: absolute;
      top: 60px;
      left: 0;
      z-index: 1;
      width: 100%;
      max-height: calc(100vh - 60px);
    }
    &.fixed {
      padding: 12px 0 15px;
      background: ${color.white};
      border-radius: 0 0 20px 20px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    margin: 15px 0;
    .green-button {
      padding: 0;
      width: 48px;
    }
  }

`;


export default () => {
  const handleScroll = () => {
    const elToFix = document.getElementById('app-header');
    if (window.pageYOffset > elToFix.offsetTop) {
      elToFix.classList.add('fixed');
    } else {
      elToFix.classList.remove('fixed');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
    <AppHeader id="app-header" className='app-header'>
      <div className={"desktop-wrapper"}>
        <Logo to={'/'} text={process.env.OWNER}/>
        <MainMenu/>
        <HeaderLineLink/>
        <Hamburger/>
      </div>
    </AppHeader>
  );
};