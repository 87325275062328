import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { breakpoints, color, wrapper } from '../../utils/style';

const BreadcrumbsWrap = styled('div')`
  ${wrapper}
  padding: 15px 0 30px;
  div {
    display: inline-flex;
  }
  .sep {
    margin: 0 15px;
    font-size: 14px;
  }
  span {
    color: ${color.black};
  }
  a {

    line-height: 20px;
    &:active, &:hover {
     svg {
        path {fill: ${color.red} !important;}
        filter: drop-shadow(1px 1px 6px ${color.lightRed});
      }
    }  
  }
  @media (max-width: ${breakpoints.md}) {
    margin: 0 0 20px;
    padding: 15px 0 0;
  }
`;

const Breadcrumbs = ( data ) => {
  const separator = ' / ';
  const breadcrumbs =  data.breadcrumbs;
  const page_title = data.page_title;
  return (
     <BreadcrumbsWrap className="breadcrumbs">
      {breadcrumbs && breadcrumbs.length > 0 ?
        breadcrumbs.map((breadcrumb, index) =>
          <div key={index + breadcrumb.slug} >
            <Link to={breadcrumb.slug ? '/' + breadcrumb.slug + '/' : '/'}
                  aria-label={!breadcrumb.slug ? process.env.OWNER : breadcrumb.title }>
              {!breadcrumb.slug ? 
                <IcomoonIcon icon="home" color={color.red} size={18}/>
                : <span>{breadcrumb.title}</span>
              }
            </Link>
            <span className="sep">{separator}</span>
          </div>
        ) : ''}
      <span>{page_title}</span>
    </BreadcrumbsWrap>
  )
};

export default Breadcrumbs